import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/About',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/Serve',
		name: 'Serve',
		component: () => import('../views/Serve.vue')
	},
	{
		path: '/Enable',
		name: 'Enable',
		component: () => import('../views/Enable.vue')
	},
]

const router = new VueRouter({
	routes
})

export default router
