<template>
	<div class="h-wrapper">
		<div class="header" id="header">
			<div class="container flex-ac">
				<router-link to="/" class="logo flex-ac">
					<img src="../assets/image/logo.png" alt="">
				</router-link>
				<el-menu :default-active="$route.path" :router="true" class="el-menu-nav" mode="horizontal">
					<el-menu-item index="/">首页</el-menu-item>
					<el-menu-item index="/Serve">产品服务</el-menu-item>
					<el-menu-item index="/Enable">赋能行业</el-menu-item>
					<el-menu-item index="/About">关于我们</el-menu-item>
				</el-menu>
				<el-button type="primary" size="small" class="btn-login" round @click="toLogin">登录</el-button>
				<el-dropdown>
					<div class="el-dropdown-lang">
						<span class="iconfont icon-yuyan"></span>
						<span>中文</span>
						<span class="el-icon-caret-bottom"></span>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>中文</el-dropdown-item>
						<el-dropdown-item>中文</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div class="menu-icon" @click="drawer = true"><i class="iconfont icon-menu"></i></div>
			</div>
		</div>
		<el-drawer :visible.sync="drawer" :direction="direction" size='60%'>
			<router-link to="/" class="logo flex-ac">
				<img src="../assets/image/logo.png" alt="">
			</router-link>
			<el-menu :default-active="$route.path" :router="true" class="m-menu-nav" mode="vertical">
				<el-menu-item index="/">首页</el-menu-item>
				<el-menu-item index="/Serve">产品服务</el-menu-item>
				<el-menu-item index="/Enable">赋能行业</el-menu-item>
				<el-menu-item index="/About">关于我们</el-menu-item>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: '1',
				drawer: false,
				direction: 'ltr',
			};
		},
		mounted() {},
		methods: {
			toLogin(){
				const externalUrl = 'https://ystransfer.com/manage/';
				window.location.href = externalUrl;
			}
		}
	}
</script>
<style>
	.h-wrapper {
		height: 72px;
	}

	.header {
		position: fixed;
		width: 100%;
		z-index: 999;
		left: 0;
		right: 0;
		top: 0;
		height: 72px;
		transition: .3s all;
		background-color: #ffffff;
	}

	.menu-icon {
		cursor: pointer;
		display: none;
	}

	.menu-icon .iconfont {
		font-size: 20px;
		color: #2566FF;
	}

	.header>.container {
		height: 100%;
	}

	.logo img {
		width: 150px;
	}

	.header .el-menu-nav {
		margin-left: auto;
	}

	.h-wrapper .el-menu.el-menu--horizontal {
		background-color: transparent;
		border: 0;
	}

	.h-wrapper .el-menu>.el-menu-item:not(.is-disabled):focus,
	.h-wrapper .el-menu>.el-menu-item:not(.is-disabled):hover,
	.h-wrapper .el-menu>.el-submenu .el-submenu__title:hover {
		background-color: transparent;
	}

	.h-wrapper .el-menu>.el-menu-item {
		color: #666666;
		font-size: 16px;
		border: 0 !important;
		height: 40px;
		line-height: 40px;
	}

	.h-wrapper .el-menu>.el-submenu .el-submenu__title {
		color: #666666;
		font-size: 16px;
		border: 0 !important;
	}

	.h-wrapper .el-menu-item,
	.el-submenu__title {
		font-size: 16px;
		border: 0 !important;
	}

	.h-wrapper .el-menu .el-menu-item:not(.is-disabled):focus,
	.h-wrapper .el-menu .el-menu-item:not(.is-disabled):hover {
		outline: 0;
		color: #2566FF;
	}

	.h-wrapper .el-menu>.el-menu-item.is-active {
		color: #2566FF;
	}

	.h-wrapper .el-menu>.el-menu-item.is-active::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		height: 3px;
		background: #2566FF;
	}

	.h-wrapper .el-menu>.el-submenu:focus .el-submenu__title,
	.h-wrapper .el-menu>.el-submenu:hover .el-submenu__title {
		color: #2566FF;
	}

	.h-wrapper .el-menu>.el-submenu.is-active .el-submenu__title {
		color: #2566FF;
	}

	.header .btn-login {
		margin-left: 30px;
		font-size: 14px !important;
	}

	.el-dropdown-lang {
		display: flex;
		align-items: center;
		margin-left: 25px;
	}

	.el-dropdown-lang .iconfont {
		margin-right: 5px;
	}

	@media (min-width:1400px) {
		.el-menu-nav>li {
			margin-left: 40px !important
		}
	}

	.el-drawer.ltr {
		background-color: #ffffff;
	}

	.h-wrapper .m-menu-nav {
		border: 0;
	}

	.el-drawer .logo {
		padding: 0 20px;
		margin-bottom: 20px;
	}

	.el-drawer__close-btn {
		outline: 0;
	}

	@media (max-width:1000px) {
		.el-menu-nav {
			display: none;
		}

		.menu-icon {
			display: block;
			margin-left: auto;
		}
	}
</style>