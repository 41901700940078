<template>
	<div class="footer">
		<div class="container ">
			<div class="foot-main flex">
				<div class="foot-left">
					<div class="foot-logo flex-ac">
						<img src="../assets/image/foot-logo.png" alt="">
					</div>
				</div>
				<div class="foot-body flex flex_bd">
					<div class="foot-link">
						<ul class="foot-nav-link">
							<li><router-link to="/">首页</router-link></li>
							<li><router-link to="/">产品介绍</router-link></li>
							<li><router-link to="/">关于我们</router-link></li>
						</ul>
					</div>
					<div class="foot-link">
						<ul >
							<li>联系我们</li>
							<li><span>邮箱：</span>vipcustom@ystransfer.com</li>
							<li><span>地址：</span>深圳市福田区深南大道6021号喜年中心大厦A座1409室</li>
						</ul>
					</div>
				</div>
				<div class="foot-wx">
					<img src="../assets/image/erweima.png" alt="">
					<p>关注我们</p>
				</div>
			</div>
			<div class="copyright">
				<img src="../assets/image/an.png" alt="" />
				<!-- <a href="">粤公网安备44030502006064号</a><span class="line">|</span><a href="">粤ICP备20072242号-1</a> -->
				<span class="line">|</span>Copyright © 云闪汇2024版权所有
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Foot',
	}
</script>
<style>
	.foot-link{
		margin-right: 180px;
	}
	.foot-link:last-child{
		margin-right: 0;
	}
	.foot-link ul li{
		margin-bottom: 25px;
	}
	.foot-link ul li:last-child{
		margin-bottom: 0;
	}
	.foot-link ul li a:hover{
		opacity: .7;
	}
	.foot-link ul li span{
		color: #666666;
	}
	.foot-wx{
		text-align: center;
	}
	.foot-wx p{
		margin-top: 10px;
		color: #666666;
	}
	.foot-left{
		margin-right: 140px;
	}
	.foot-text{
		font-weight: bold;
		font-size: 20px;
	}
	.footer{
		background-color: #000000;
		color: #ffffff;
	}
	.foot-main{
		padding: 80px 0 40px 0;
	}
	.copyright{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		padding: 30px 0;
		color: #666666;
		border-top: 1px solid rgba(255, 255, 255, .1);
	}
	.copyright .line{
		padding: 0 15px;
	}
	.copyright img{
		margin-right: 15px;
	}
	@media (max-width:1200px) {
		.foot-left{
			margin-right: 70px;
		}
		.foot-link{
			margin-right: 90px;
		}
	}
	
	@media (max-width:1000px) {
		.foot-main{
			flex-wrap: wrap;
		}
		.foot-left{
			width: 100%;
			margin-bottom: 30px;
			margin-right: 0;
		}
		.foot-main .flex_bd{
			display: block;
			width: 100%;
			flex: unset;
		}
		.foot-logo{
			margin-bottom: 10px;
		}
		.foot-link{
			width: 100%;
			margin-right: 0;
		}
		.foot-wx{
			width: 100%;
			text-align: center;
			margin-top: 30px;
		}
		.foot-link ul li{
			margin-bottom: 15px;
		}
		.foot-nav-link{
			display: flex;
			padding-bottom: 20px;
		}
		.foot-nav-link li{
			width: 33.33%;
		}
		.copyright{
			font-size: 14px;
			line-height: 2;
		}
		
	}
	
	
	
	
	
	
	
	
	
	
	
</style>
































