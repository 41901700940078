import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import BaiduMap from 'vue-baidu-map'
import 'element-ui/lib/theme-chalk/index.css';
import wow from 'wowjs';
import 'wowjs/css/libs/animate.css';

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.prototype.$wow = wow
Vue.use(BaiduMap, {
  ak: 'YOUR_APP_KEY' // 替换为你在百度地图开发者平台申请的密钥
})
// import style
import 'swiper/swiper-bundle.css'

import "./assets/fonts/iconfont.css";
import "./assets/css/css.css";

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
})