<template>
	<div class="page-container">
		<Head />
		<div class="index-banner">
			<swiper class="swiper gallery-banner" :options="swiperOptionThumbs" ref="swiperThumbs">
				<swiper-slide>
					<div class="banner-item" :style="{backgroundImage:'url('+banner+')'}">
						<div class="container">
							<div class="banner-grid wow fadeInDown">
								<div class="text-lg">全球化跨境贸易数字化系统</div>
								<div class="text-sm">用云闪汇——让跨境支付变得更简单</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- <div class="swiper-pagination" slot="pagination"></div>
			<div class="swiper--next" slot="button-next">
			</div>
			<div class="swiper--prev" slot="button-prev">
			</div> -->
		</div>
		<div class="page-wrapper">
			<div class="container">
				<div class="page-header wow fadeInDown">
					<h2>贸易数字化系统</h2>
					<p>云闪汇数字赋能全球跨境贸易企业，为跨境贸易企业提供全智能数字化系统。围绕跨境贸易企业的“关、税、汇、退、运”提供一体化合规解决方案。能够帮助企业真正做到阳光化、简易化、低成本，同时能够为电商出口贸易类企业提供完整的“第三方电商平台”的智能化运营及决策系统功能。云闪汇数字在帮助企业达到降本增效的目的同时，还能够提供专业的决策方案帮助客户提升交易额。</p>
				</div>
				<div class="index-list-card wow fadeInDown">
					<div class="list-card-photo">
						<img src="../assets/image/pic-1.png" alt="" />
					</div>
					<div class="flex_bd">
						<h3>全球化聚合</h3>
						<p>云闪汇数字在中国境内聚合微信支付、支付宝、银联云闪付、多家银行及各大主流支付公司的支付接口，为电商客户提供公众号、O2O、APP、WeB、聚合扫码的中国本地及跨境支付功能。能够适配客户的多元化支付需求，并提供定制化多层级的电子支付记账及配套的清算服务，为跨境贸易客户提供全链路的快捷、高效、合规、低成本的服务能力。</p>
					</div>
				</div>
				<div class="index-list-card wow fadeInDown">
					<div class="list-card-photo">
						<img src="../assets/image/pic-2.png" alt="" />
					</div>
					<div class="flex_bd">
						<h3>阳光税务</h3>
						<p>针对出口电商企业无采购进项发票且满足跨境综试区的条件的电商客户，我们能帮助客户提供合法合规的阳光化出口报关、资金申报入境、以及按照入账金额进行4%的应税率进行企业所得税的缴税，实际缴税额为：收入*4%*25%（一般企业缴纳25%的企业所得税），即按照入账金额的1%进行企业所得税缴纳。
对于持有采购（进项）发票的客户，我们能提供9610的阳关报关出口、提供供应商货款的委托付款等系统级服务，境外销售货款汇款后我们能帮助客户完成退税。</p>
					</div>
				</div>
				<div class="index-list-card wow fadeInDown">
					<div class="list-card-photo">
						<img src="../assets/image/pic-3.png" alt="" />
					</div>
					<div class="flex_bd">
						<h3>AI智能运营</h3>
						<p>为出口电商客户提供完整的运营投入成本智能决策、广告投放方案及智能决策、财务智能预测及分析等全套智能优化决策方系统。</p>
						<p>
							以商户的ERP数据为依据，成功大卖的数据，行业大数据作为参考，围绕消费者画像、销售品类、建立不同的营销场景，结合商户量身订制整个营销方案。
							通过BI数据看板，实时监控营销活动中每个节点的目标达成情况，通过大数据智能分析，提供需要实时调整的营销决策建议，让商户实现营销效果最大化。
						</p>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
		},
		data() {
			return {
				banner:require('../assets/image/banner-@1x.jpg'),
				swiperOptionThumbs: {
					loop: true,
					autoplay: 4000,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper--next',
						prevEl: '.swiper--prev'
					}
				},
			};
		},
		methods: {
			
		},
		mounted() {
			new this.$wow.WOW().init();
		}
	}
</script>
